import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCfmPhysicianLogs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/cfm-logs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/index-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysician(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePhysician(ctx, physicianData) {
      return new Promise((resolve, reject) => {
        const {
          id,
          name,
          cpf,
          crm,
          email,
          phone,
          monthly_income,
          address,
        } = physicianData

        address.postalCode = address.postalCode.replace(/\D/g, '')
        const data = {
          name,
          cpf: cpf.replace(/\D/g, ''),
          crm,
          email,
          phone: phone.replace(/\D/g, ''),
          monthly_income,
          address,
        }
        axios
          .put(`/physician/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserPhysician(ctx, physicianData) {
      return new Promise((resolve, reject) => {
        const {
          user_id: id,
          name,
          cpf,
          email,
        } = physicianData

        const data = {
          name,
          cpf: cpf.replace(/\D/g, ''),
          email,
        }
        axios
          .put(`/user/user-physician/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    refuseAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        const {
          id,
          refuse_reason,
        } = data

        axios
          .put(`/account/${id}/refused`, { refused_reason: refuse_reason })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStates(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/states', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/cities', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
